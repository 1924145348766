import Cookies from 'js-cookie'
import { ORIGIN_COOKIE } from 'lib/utils'
import { parseCookies } from 'nookies'
import React, { createContext, ReactNode, useContext, useState } from 'react'

export const isEonxEnvironment = () => {
  const cookies = parseCookies()
  const origin = (cookies[ORIGIN_COOKIE] || '').toLowerCase()
  const isOriginEonx = origin === 'eonx'
  return isOriginEonx
}

export const EonxEnvController = ({ children }: { children: ReactNode }) => {
  // Get initial value from Cookies
  const [isEonxEnv, setEonxEnv] = useState(isEonxEnvironment())

  const enableEonx = () => {
    setEonxEnv(true)
    Cookies.set(ORIGIN_COOKIE, 'eonx')
  }

  const disableEonx = () => {
    setEonxEnv(false)
    Cookies.set(ORIGIN_COOKIE, 'none')
  }

  return (
    <EonxEnvContext.Provider value={{ enableEonx, disableEonx, isEonxEnv }}>
      {children}
    </EonxEnvContext.Provider>
  )
}

export const EonxEnvContext = createContext<{
  isEonxEnv: boolean
  enableEonx: () => void
  disableEonx: () => void
}>({
  isEonxEnv: false,
  enableEonx: () => false,
  disableEonx: () => false,
})

export const useEonxEnvContext = () => {
  return useContext(EonxEnvContext)
}
